import cx from 'classnames';
import React from 'react';

const List = ({
	children,
	as: Component = 'ul',
	className = '',
	type = 'column',
}: {
	children: React.ReactNode;
	as?: React.ElementType;
	type?: 'grid' | 'column' | 'grid-column';
	className?: string;
}): JSX.Element => {
	return (
		<Component
			className={cx('tick-list', {
				[`tick-${type}`]: type,
				[className]: !!className,
			})}>
			{children}
		</Component>
	);
};

export default List;
