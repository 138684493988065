// i18next-extract-mark-ns-start products-sabr

import { LINKS, SABR_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import ContactForm from '@components/ContactForm';
import List from '@components/List';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductSabrPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const icon = getImage(data.icon.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>
							SABR is a proven shaft, gear and bearing conception and
							design package.
						</h2>
					</Trans>

					{icon && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={icon}
								alt="SABR"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_2">
						<p>
							Developed to integrate into the design process and
							reduce product development time, SABR provides an
							intuitive graphical interface allowing the transmission
							system to be modelled at a level of detail appropriate
							to the current design phase. &nbsp;Additionally, it
							allows sensitivity studies to determine the effects of
							different geometric features, bearing types and gear
							positions, with immediate results.
						</p>
						<p>
							The solvers themselves are based on engineering
							standards as well as Realis Simulation formerly Ricardo
							Software’s product design, manufacturing and testing
							experience, and are continually updated to be kept in
							line with real-world test and development programmes.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{SABR_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<PageSection title={t("_KeyFeatures")} layout="wide">
				<Trans i18nKey="features">
					<List type="grid">
						<li>
							Simple and intuitive graphical interface for quick and
							accurate model building
						</li>
						<li>Parallel, epicyclic and bevel gears</li>
						<li>
							Coupling, point loads and torque-dependent load
							applications
						</li>
						<li>
							Gear design and positioning to achieve targeted NVH
							criteria
						</li>
						<li>
							Bearing analysis with life calculation and stress plots
							accounting for loading and misalignment
						</li>
						<li>Synchroniser selection and analysis</li>
						<li>
							Gear misalignment analysis, including an interface to
							FEA for gear body deformation
						</li>
						<li>Shaft design and analysis</li>
						<li>Duty cycles with multiple and complex load paths</li>
						<li>Comprehensive results suite</li>
					</List>
				</Trans>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default ProductSabrPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-sabr", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/sabr-hero.jpg" }) {
		...imageBreaker
	}
	icon: file(relativePath: { eq: "content/rs_sabr.png" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { products: { in: "SABR" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 6
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;



